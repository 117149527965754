import { render, staticRenderFns } from "./cartEmpty.vue?vue&type=template&id=24d8e856&scoped=true&"
import script from "./cartEmpty.vue?vue&type=script&lang=js&"
export * from "./cartEmpty.vue?vue&type=script&lang=js&"
import style0 from "./cartEmpty.vue?vue&type=style&index=0&id=24d8e856&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d8e856",
  null
  
)

export default component.exports