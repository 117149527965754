<template>
    <div class="cartEmpty">
        <div class="top">
            <div class="empty">
                <img src="./../../assets/images/cart/empty.png" alt="">
                <p>购物车什么都没有</p>
                <van-button type="primary" color="#D53A26" round size="mini" @click="$router.push('/')">去商城</van-button>
            </div>

<!--            <integral-mall />-->
        </div>
        <!-- 这句话在 /cart/index.vue里面还有一句 -->
        <p class="tips">温馨提示：系统每10分钟自动清空购物车</p>
    </div>
</template>

<script>
// import integralMall from '../../components/integralMall.vue'
export default {
  // components: { integralMall },
    name: "Cart"
}

</script>
<style lang="less" scoped>
 .cartEmpty {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .top {
        flex: 1;
        width: 100%;
    }
 }
.empty {
    background-color: #fff;
    padding: 35px 0;
    text-align: center;

    img {
        width: 180px;
        height: 116px;

    }

    p {
        line-height: 14px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #615F60;
        margin: 13px 0 30px 0;
        text-align: center;
    }

    .van-button {
        width: 109px;
    }
}
.tips {
    line-height: 10px;
    font-size: 10px;
    font-weight: 400;
    color: #FF0000;
    padding: 10px 30px;
}
.IntegralMall {
    margin-top: 22px;
}
</style>
