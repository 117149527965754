<template>
  <div class="cartList page-gray">
    <!-- 购物车为空状态 -->
    <cart-empty v-if="cartList.length === 0"/>
    <div class="cartContent" v-else>
      <div class="cartBody">
        <div class="cartCount">共{{ totalNum }}件商品</div>
        <van-checkbox-group v-model="cartCheck">
          <div v-for="item in cartList" :key="item.cartId" :class="{item: true, simple: item.skuType === 2}">
            <div class="top">
              <div class="goodsInfo">
                <van-swipe-cell :right-width="60">
                  <p class="skuInfo timeLimit" style="margin-top: 10px;">
                    <van-count-down :time="item.cart.createTime" format="HH:mm:ss" @finish="(()=>finish(item.cart.cartId))"/> 后过期
                  </p>
                  <div class="goods">
                    <div class="radio">
                      <van-checkbox
                          :name="item"
                          :disabled=" ['1','2'].includes(item.cart.status)"
                          :icon-size="px2rem(19)"
                          checked-color="#D82225"
                      >
                      </van-checkbox>
                    </div>
                    <div class="base">
                      <div class="imgBox">
                        <div class="notAvailable" v-if="item.cart.status==='1'">
                          <div class="circle">已过期</div>
                        </div>
                        <div class="notAvailable" v-if="item.cart.status==='2'">
                          <div class="circle">商品已下架</div>
                        </div>
                        <img
                            :src="item.catData.releaseProduct.image"
                            alt=""
                        />
                      </div>
                      <div class="right">
                        <div class="name van-multi-ellipsis--l2" :style="{color: ['1','2'].includes(item.cart.status) ? '#ccc' : '#333'}">
                          <span class="ys" v-show="item.catData.releaseProduct.libckflag ==0">预售</span>
                          <span class="xh" v-show="item.catData.releaseProduct.libckflag ==1">现货</span>
                          {{ item.catData.releaseProduct.name }}
                        </div>

                        <div class="tagList">
                          <span class="tag" v-show="item.catData.releaseProduct.deliveryMode =='2'">配送到校</span>
                          <span class="tag" v-show="item.catData.releaseProduct.deliveryMode =='1'">快递到家</span>
                        </div>
                        <div v-show="item.catData.releaseProduct.deliverGoodsTemp === '1'"
                             class="deliveryTime">下单{{item.catData.releaseProduct.day}}天内发货</div>
                        <div v-show="item.catData.releaseProduct.deliverGoodsTemp === '2'"
                             class="deliveryTime">预计配送时间：{{ item.catData.releaseProduct.deliverGoodsDate }}</div>
                        <div v-show="item.catData.releaseProduct.deliverGoodsTemp === '3'"
                             class="deliveryTime"
                             v-html="item.catData.releaseProduct.deliverGoodsContent">
                        </div>
                        <div class="price">
                          <div class="money">
                            <span>¥</span>
                            <span>{{ (item.catData.totalPrice/item.catData.totalNum).toFixed(2) }}</span>
                          </div>
                          <div class="number">
                            x{{ item.catData.totalNum }}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <template #right>
                    <van-button square
                                @click="delShoppingCart(item.cart.cartId)"
                                :style="{height: '100%', 'width': '36px', 'fontSize':'12px', padding: 0, marginLeft: '23px'}"
                                text="删除" type="danger" color="#FF553E" class="delete-button"/>
                  </template>
                </van-swipe-cell>

                <div class="skuInfo">
                  <!-- 校服包才显示 -->
                  <div class="title">
                    <span>商品明细:</span>
<!--                    <div class="sku" @click="changeSku(item)">修改规格 ></div>-->
                  </div>

                  <div class="skuItem" v-for="(sku,index) in item.catData.skuProductData" :key="index">
                    <!-- 校服包才显示 -->
                    <span class="name"> {{ sku.name }}  </span>
                    <span class="amount">x{{ sku.num*item.catData.totalNum }}</span>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </van-checkbox-group>
      </div>
      <!-- 这句话在 /cart/cartEmpty.vue里面还有一句 -->
      <p class="tips">温馨提示：加入购物车商品每10分钟过期，请尽快处理</p>
      <!-- 价格为分  :price="totalPrice" -->
      <van-submit-bar
          button-color="#D53A26"
          :price="totalPrice"
          label="总计:"
          button-text="去结算"
          @submit="submit"
          :disabled="cartCheck.length === 0"
      >
        <van-checkbox :icon-size="px2rem(18)" @click="computedAllCheck" v-model="allCheck" checked-color="#D82225"><span
            class="allCheckText">全选</span></van-checkbox>
      </van-submit-bar>

      <new-sku ref="sku"/>
    </div>
  </div>
</template>

<script>

import cartEmpty from "./cartEmpty.vue";
import {Decimal} from "decimal.js";
import Vue from "vue";
import {transferDate} from "../../util/util";
import NewSku from "../goods/components/newSku/newSku.vue";
export default {
  name: "Cart",
  components: {NewSku, cartEmpty,},
  data() {
    return {
      allCheck: false,
      cartList: [],
      cartCheck: [], //选中的购物车， 值为 van-checnbox的name属性
    };
  },
  computed: {
    /**
     * @returns 返回合计价格， 结果应该是分
     */
    totalPrice() {
      // 这里因为是静态数据 都是300一个卡片， 所以直接乘以300，
      // 真实数据应该在这里获取真实的数据
      let totalPrice = 0;
      this.cartCheck.forEach(cart => {
        console.log(cart)
        totalPrice = new Decimal(totalPrice).add(new Decimal(cart.catData.totalPrice)).toNumber();

      })
      let c =new Decimal(totalPrice).mul(new Decimal(100)).toNumber()
      return Math.ceil(c);

    },
    totalNum(){
      let totalNum = 0;
      this.cartList.forEach(cart => {
        totalNum += cart.catData.totalNum
      })
      return totalNum;
    }
  },

  methods: {
    //过期
    finish(cartId){
      this.init();
      // 每5000毫秒清除数据
      let intervalId = setInterval(() =>{
        //手动执行购物车清空方法
        this.$http.post(this.baseMallUrl + `/h5_mall/manuallyClearShoppingCart/${cartId}`, {emulateJSON: true}).then(() => {})
        clearInterval(intervalId);
      }, 10000);
      // 取消定时器
    },
    changeSku(item) {
      this.$refs.sku.id = item.catData.releaseProduct.id;
      this.$refs.sku.showPop((data) => {
        // data 这是结果
        this.modifyShoppingCart(data.data,item.cart.cartId);
      },item.cart.propData,item.cart.basketCount)
    },
    //修改购物车
    modifyShoppingCart(data,cartId){
      let that = this;
      this.$http.post(this.baseMallUrl + `/h5_mall/modifyShoppingCart/${Vue.prototype.userId}/${Vue.prototype.deptId}/${cartId}`, data, {emulateJSON: true}).then(() => {
        const timer = setInterval(() => {
          that.init();
          clearInterval(timer);
        }, 2000);
      })
    },
    computedAllCheck() {
      this.$nextTick(() => {
        // 全选 反选
        if (this.allCheck) {
          // 先过滤被禁用的
          this.cartCheck = this.cartList.filter(e =>  !['1','2'].includes(e.cart.status));
        } else {
          this.cartCheck = []
        }
      })
    },
    /**
     * 删除购物车
     */
    delShoppingCart(cartId){
      this.$http.post(this.baseMallUrl + `/h5_mall/delShoppingCart/${cartId}`).then(res => {
        res
        this.$toast.success({
          message:'删除成功',
          onClose:()=>{
            Object.assign(this.$data, this.$options.data())
            this.init();
          }
        });
      })
    },
    init(){
      this.$http.get(this.baseMallUrl + `/h5_mall/queryShoppingCatList`).then(res => {
        let cartList = res.data;
        cartList.forEach((item)=>{
          let date = transferDate(new Date(item.cart.createTime.replace(/-/g, "/")));
          let min = date.getMinutes();
          let seconds = date.getSeconds();
          date.setMinutes(min+10);
          date.setSeconds(seconds+5);
          item.cart.createTime = date.getTime() - Date.now();
          item.catData.price = new Decimal(item.catData.totalPrice).div(new Decimal(item.catData.totalNum)).toNumber()
        });
        this.cartList = cartList;
      })
    },
    // 为了能让更好的在空购物车以及有商品的购物车中切换， 实际开发过程中可以去掉， 只是演示用
    toggleEmpty(val) {
      this.cartList = (val === 'data' ? [
        {
          skuType: 1, // 1 校服包 , 2 单规格
          isOverdue: false, // 是否过期， 过期了复选框禁用
          cartId: Math.floor(Math.random() * 1000),
          notAvailable: false
          /**
           * .... 其他的就不写了， 其他的就是价格、商品信息之类的了
           */
        }, {
          skuType: 2, // 1 校服包 , 2 单规格
          isOverdue: false, // 是否过期， 过期了复选框禁用
          cartId: Math.floor(Math.random() * 1000),
          notAvailable: true
          /**
           * .... 其他的就不写了， 其他的就是价格、商品信息之类的了
           */
        }, {
          skuType: 2, // 1 校服包 , 2 单规格
          isOverdue: true, // 是否过期， 过期了复选框禁用
          cartId: Math.floor(Math.random() * 1000),

          /**
           * .... 其他的就不写了， 其他的就是价格、商品信息之类的了
           */
        }
      ] : []);
    },
    submit() {
      let reserveProductData=[];
      let anyProductData=[];
      this.cartCheck.forEach(cartEmpty=>{
        if(cartEmpty.catData.releaseProduct.deliveryMode == 2){
          reserveProductData.push(cartEmpty.catData)
        }
        if(cartEmpty.catData.releaseProduct.deliveryMode == 1){
          anyProductData.push(cartEmpty.catData)
        }
      })

      localStorage.setItem('reserveProductData', JSON.stringify(reserveProductData));
      localStorage.setItem('anyProductData', JSON.stringify(anyProductData));
      localStorage.removeItem('store')
      this.$router.push('/order/submit')
    }
  },

  watch: {
    cartCheck() {
      let canSelectLen = this.cartList.filter(e => !['1','2'].includes(e.cart.status)).length;
      this.allCheck = canSelectLen==0? false :(canSelectLen === this.cartCheck.length) // 当可选数量跟已经选择的数量一样 就设置全选按钮选中
    }
  },
  created() {
    Object.assign(this.$data, this.$options.data())
    this.init();
  },
  activated() {
    Object.assign(this.$data, this.$options.data())
    this.init();
  }


};
</script>



<style lang="less" scoped>
.cartList {
  height: 100%;
  position: relative;
  min-height: 100%;
}
.cartContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .cartBody {
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling : touch;

  }
  .cartCount {
    padding: 10px 18px;
    line-height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    padding: 10px 18px;

  }
  .van-checkbox-group {
    padding: 0 18px;
    .item {
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 15px;
      &.simple {
        .skuItem {
          margin-bottom: 10px;
        }
      }
      div.top {
        display: flex;
      }
      .radio {

        margin: 0 14px 0 0;

        > img {
          height: 19px;
          width: 19px;
        }
      }
      .goodsInfo {
        width: 100%;
        .goods {
          margin-top: 17px;
          display: flex;
          padding-right: 7px;
          align-items: center;
          padding-left: 13px;
          padding-bottom: 5px;
          .base {
            display: flex;
          }
          .imgBox {
            width: 95px;
            height: 80px;
            border-radius: 5px;
            min-width: 95px;
            margin-right: 14px;
            position: relative;
            .notAvailable {
              position: absolute;
              width: 95px;
              height: 80px;
              border-radius: 5px;
              background-color: rgba(255,255,255,.4);
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              .circle {
                width: 41px;
                height: 41px;
                background: rgba(0,0,0,.5);
                border-radius: 50%;

                line-height: 41px;
                text-align: center;

                font-size: 13px;
                color: rgba(255,255,255,.9);
              }
            }
            img {
              width: 95px;
              height: 80px;
              border-radius: 5px;

            }
          }

          .right {
            padding-top: 3px;
            // height: 80px;
            flex: 1;
            .name {
              line-height: 14px;
              font-size: 12px;
              font-weight: 400;
              color: #333333;

              .ys {
                width: 32px;
                background: linear-gradient(264deg, #f58130, #ef2514);
                border-radius: 2px;

                font-size: 12px;
                font-weight: 500;
                color: #F4F4F4;
                line-height: 12px;
                text-align: center;
                padding: 2px 0;
                display: inline-block;
                margin-right: 3px;

              }

              .xh {
                width: 32px;
                background: linear-gradient(264deg, #4db3ee, #00A0E9);
                border-radius: 2px;

                font-size: 12px;
                font-weight: 500;
                color: #F4F4F4;
                line-height: 12px;
                text-align: center;
                padding: 2px 0;
                display: inline-block;
                margin-right: 3px;

              }
            }

            .tagList {
              display: flex;
              margin: 7px 0 5px 0;
              .tag {
                outline: 1px solid #F02336;
                border-radius: 2px;
                line-height: 10px;
                font-size: 10px;
                font-weight: 400;
                color: #F02336;
                padding: 2px 7px;
              }
            }
            .deliveryTime {
              margin: 0px 0 5px 0;
              font-size: 14px;
              color: #F3B66A;
            }

            .price {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 16px;
              .money {
                font-size: 0;
                span {
                  font-size: 12px;
                  font-weight: 500;
                  color: #D53A26;
                  margin-right: 3px;
                  line-height: 15px;

                  &:last-child {
                    font-size: 15px;
                    font-weight: bold;
                  }
                }
              }
              .number {
                padding: 4px 4px;
                background: #FFFFFF;
                border: 1px solid #DCDCDC;
                border-radius: 4px;
                line-height: 12px;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
              }

              .sku {
                background: #EEEEEE;
                border-radius: 2px;
                padding: 3px 6px;

                line-height: 11px;
                font-size: 10px;
                font-weight: 400;
                color: #999999;
              }
            }
          }
        }

        .skuInfo {
          .title {
            padding: 25px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              line-height: 12px;
              font-size: 12px;
              font-weight: 400;
              color: #333;
            }
            .sku {
              line-height: 12px;
              font-size: 12px;
              font-weight: 400;
              color: #D53A26;
            }
            img {
              width: 9px;
              height: 5px;
              transition: all .2s;
              &.close {
                transform: rotateZ(180deg);
              }
            }
          }

          .skuItem {
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 5px;
            background: #F9F9F9;
            border-radius: 3px;
            font-size: 12px;
            color: #666;
            .name {
              width: 80%;
              display: inline-block;
            }
            .amount{
              text-align: right;
              width: 20%;
              display: inline-block;
            }
          }
        }

        .handle {
          display: flex;
          justify-content: space-between;
          padding: 13px 17px 12px 0;
          .money {
            span {
              font-weight: 500;
              &:first-child {
                font-size: 10px;
                color: #333333;
              }
              &:nth-child(2) {
                font-size: 12px;
                color: #D53A26;
                margin: 0 3px;
              }
              &:nth-child(3) {
                font-size: 15px;
                color: #D53A26;
              }
            }
          }

          .count {
            span {
              line-height: 10px;
              font-size: 10px;
              font-weight: 500;
              color: #333333;

              &:last-child {
                color: #D53A26;
                margin-left: 3px;
              }
            }
          }
        }
      }
      .timeLimit {
        font-size: 14px;
        font-weight: 500;
        color: #D53A26;
        span {
          font-size: 12px;
          background: #F9EDEB;
          padding: 3px;
        }
        .van-count-down {
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          color: #D53A26;
          margin-left: 5px;
        }
      }

      .skuInfo, .handle {
        padding-right: 13px;
        padding-left: 13px !important;
      }
      div.bottom {
        border-top: 1px solid #eee;
        padding: 7px 45px 10px 45px;
        p {
          line-height: 19px;

          .key {
            font-size: 10px;
            font-weight: 500;
            color: #333333;
            display: inline-block;
            width: 40px + 28px;
          }

          .value {
            font-size: 10px;
            font-weight: 400;
            color: #999999;
          }
        }

      }
    }
  }
}
.allCheckText {
  font-size: 15px;
  font-weight: 400;
  color: #999999;
}

.tips {
  line-height: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #FF0000;
  padding: 10px 30px;

}

.van-submit-bar {
  position: relative;
}

::v-deep .van-submit-bar__text {
  > span {
    font-size: 12px;
    font-weight: 500;
    color: #212121;
  }

  > .van-submit-bar__price {
    font-weight: 500;
    color: #D53A26;
    margin-left: 5px;
    span {
      font-size: 15px;
      margin-left: 3px;
    }
  }
}

::v-deep .van-checkbox__icon .van-icon {
  border-radius: 50%;
}
</style>
